import { paymentsClient } from '../axiosInstance';
import { ISubscription, IUpdateSubscriptionData } from 'features/subscription/subscriptionTypes';

const paymentSubscriptions = process.env.REACT_APP_PAYMENTS_API + '/subscriptions'

export const fetchSubscriptionById = async (subscriptionId: string) => {
    return await paymentsClient.get<ISubscription>(paymentSubscriptions + `/${subscriptionId}`);
}

export const fetchSubscriptionByOrganozationId = async (organozationId: number) => {
    return await paymentsClient.get<ISubscription>(paymentSubscriptions + `/organizations/${organozationId}`);
}

export const changeSubscriptionInfo = async (model: IUpdateSubscriptionData) => {
    return await paymentsClient.patch<ISubscription>(
        paymentSubscriptions + `/${model.subscriptionId}`,
        model);
}

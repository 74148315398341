import { apiClient } from './axiosInstance';
import {
  IFetchInvitesParams,
  IInviteMemberData,
} from 'features/invitesList/invitesListTypes';

export const fetchInvitesList = async (params: IFetchInvitesParams | void) => {
  if (params) {
    const { skip, take } = params;
    const skipQuery = `skip=${skip}`;
    const takeQuery = `&take=${take}`;
    const queryStr = `?${skipQuery}${takeQuery}`;
    return await apiClient.get(`/invites${queryStr}`);
  } else {
    return await apiClient.get(`/invites`);
  }
};

export const inviteMember = async (invitationData: IInviteMemberData) => {
  return await apiClient.post('/invites', {
    userEmail: invitationData.email,
    role: invitationData.role,
    projectsIds: invitationData.projectsIds,
  });
};

export const resendInvite = async (inviteId: number) => {};

export const deleteInvite = async (inviteId: number) => {};

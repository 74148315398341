import React, { useEffect, useState } from 'react';
import styles from './SubscriptionPlans.module.scss';
import { useSelector } from 'react-redux';
import { pricesSelector } from 'features/prices/pricesSlice';
import { subscriptionInfoSelector } from 'features/subscription/subscriptionSlice';
import { itemsTotalCountMembersSelector } from 'features/members/membersSlice';
import { IUpdateSubscriptionData } from 'features/subscription/subscriptionTypes';
import { updateSubscriptionInfo } from 'features/subscription/subscriptionActions';
import { useAppDispatch } from 'app/store';
import Button from 'components/shared/Button';
import { getCurrencySymbol } from 'helpers/getCurrencySymbol';

interface SubscriptionPlansProps {
  onClose: () => void;
  showSuccessNotification: (message: string) => void;
  showErrorNotification: (message: string) => void;
}

const SubscriptionPlans: React.FC<SubscriptionPlansProps> = ({ onClose, showSuccessNotification, showErrorNotification }) => {
  const dispatch = useAppDispatch();
  const prices = useSelector(pricesSelector);
  const subscriptionInfo = useSelector(subscriptionInfoSelector);
  const membersCount = useSelector(itemsTotalCountMembersSelector);

  const [selectedProductId, setSelectedProductId] = useState(subscriptionInfo.productId);
  const [selectedCurrency, setSelectedCurrency] = useState('usd'); // Валюта по умолчанию
  const [availableCurrencies, setAvailableCurrencies] = useState<string[]>([]); // Список доступных валют

  useEffect(() => {
    const uniqueCurrencies = [...new Set(prices.map(price => price.currency))];
    setAvailableCurrencies(uniqueCurrencies);
    setSelectedCurrency(subscriptionInfo.currency); // Установим первую валюту по умолчанию
  }, [prices]);

  const filteredPrices = prices
    .filter(price => price.currency === selectedCurrency)
    .sort((a, b) => a.productName.localeCompare(b.productName));

  const productPrices = filteredPrices.reduce((acc, price) => {
    if (!acc[price.productId]) {
      acc[price.productId] = {
        id: price.productId,
        name: price.productName,
        description: price.productDescription,
        prices: [],
      };
    }
    acc[price.productId].prices.push(price);
    return acc;
  }, {} as Record<string, { id: string, name: string; description: string; prices: typeof prices }>);

  const handleSubmit = () => {
    let newPrice = filteredPrices.find(x => 
      x.productId === selectedProductId && 
      x.interval === subscriptionInfo?.interval &&
      x.currency === subscriptionInfo.currency
    );
    
    if (!newPrice) {
      newPrice = filteredPrices.find(x => x.productId === selectedProductId);
    }

    const updateSubscriptionData: IUpdateSubscriptionData = {
      subscriptionId: subscriptionInfo.id,
      priceId: newPrice?.id ?? subscriptionInfo.priceId,
      quantity: subscriptionInfo.quantity
    };

    dispatch(updateSubscriptionInfo(updateSubscriptionData));
    onClose();
  };

  const handleCurrencyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCurrency(event.target.value);
  };

  return (
    <div className={styles.wrapper}>
      <h2>
        Current plan: <span className={styles.currentPlan}>{subscriptionInfo.name}</span>.
      </h2>

      <div className={styles.currencySelector}>
        <label htmlFor="currency">Select Currency:</label>
        <select id="currency" value={selectedCurrency} onChange={handleCurrencyChange}>
          {availableCurrencies.map((currency) => (
            <option key={currency} value={currency}>
              {currency.toUpperCase()}
            </option>
          ))}
        </select>
      </div>

      <div className={styles.plans}>
        {Object.values(productPrices).map((product) => (
          <div
            key={product.id}
            className={`${styles.plan} ${product.id === selectedProductId ? styles.currentPlanCard : ''}`}
            onClick={() => setSelectedProductId(product.id)}
          >
            <h3 className={styles.planName}>{product.name}</h3>
            <ul className={styles.priceList}>
              {product.prices.map((price) => (
                <li key={price.id} className={styles.priceItem}>
                  {getCurrencySymbol(price.currency)}{price.amount} / {price.interval}
                </li>
              ))}
            </ul>
            <ul className={styles.features}>
              {product.description?.split(', ').map((feature, index) => (
                <li key={index}>✔ {feature}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <div className={styles.planSummary}>
        <h3>Current plan summary:</h3>
        <ul>
          <li>{subscriptionInfo.name}</li>
          <li>{subscriptionInfo.interval} billing period</li>
          <li>Up to {membersCount} users</li>
        </ul>
      </div>

      <div className={styles.formFooter}>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default SubscriptionPlans;

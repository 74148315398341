import { apiClient } from './axiosInstance';
import {
  IFetchMemberTimeOffRequestsParams,
  IFetchTimeOffRequestsParams,
  IRequestingTimeOffData,
} from 'features/timeOffRequestsList/timeOffRequestsListTypes';

export const sendTimeOffRequest = async (data: IRequestingTimeOffData) => {
  return await apiClient.post(`/time-off/requests`, {
    ...data,
  });
};

export const fetchTimeOffRequestsList = async (
  params: IFetchTimeOffRequestsParams
) => {
  const { path, members, skip, take } = params;
  const membersIds = members?.map((option) => option.value);

  const pathQuery = path.length > 0 ? `${path}` : '';

  const memberFilterQuery =
    membersIds && membersIds.length > 0
      ? `&search=${membersIds.join('&search=')}`
      : '';
  const skipQuery = `skip=${skip}`;
  const takeQuery = `&take=${take}`;
  const queryStr = `?${skipQuery}${takeQuery}${memberFilterQuery}`;

  return await apiClient.get(`/time-off/requests${pathQuery}${queryStr}`);
};

export const fetchMemberTimeOffRequestsList = async (
  params: IFetchMemberTimeOffRequestsParams
) => {
  const { path, accountId, start, end } = params;
  
  const startDateQuery = start.length > 0 ? `StartDate=${start}` : '';
  const endDateQuery = `${ start.length > 0 ? '&' : '' }EndDate=${end}`;
  const queryStr = `?${startDateQuery}${endDateQuery}`; 

  const pathQuery = path.length > 0 ? `${path}` : '';

  return await apiClient.get(`/time-off/requests${pathQuery}/members/${accountId}${queryStr}`);
};

export const approveRequest = async (requestId: number) => {
  return await apiClient.get(`/time-off/requests/${requestId}/approve`);
};

export const resubmitRequest = async (requestId: number) => {
  return await apiClient.get(`/time-off/requests/${requestId}/resubmit`);
};

export const denyRequest = async (requestId: number) => {
  return await apiClient.get(`/time-off/requests/${requestId}/deny`);
};

export const deleteRequest = async (requestId: number) => {
  return await apiClient.delete(`/time-off/requests/${requestId}`);
};

import { jiraClient } from './axiosInstance';
import {IAddingJiraConnectionData, IGenerateJiraIssuesDescription} from "../features/jira/jiraTypes";

export const fetchJiraConnectionsAny = async () => {
    return await jiraClient.get(`/JiraConnections/any`);
};

export const fetchJiraConnectionsList = async () => {
    return await jiraClient.get(`/JiraConnections`);
};

export const fetchJiraConnectionInfo = async (Id: number) => {
    return await jiraClient.get(`/JiraConnections/${Id}`);
};

export const addNewJiraConnection = async (data: IAddingJiraConnectionData) => {
    return await jiraClient.post(`/JiraConnections`, {
        ...data,
    });
};

export const deleteJiraConnectionInfo = async (id: number) => {
    return await jiraClient.delete(`/JiraConnections/${id}`);
};

export const generateJiraIssueDescriptionByUrl = async (data: IGenerateJiraIssuesDescription) => {
    return await jiraClient.post(`/Issues/generate/description`, {
        ...data,
    });
};
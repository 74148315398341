import { apiClient } from './axiosInstance';
import { IAddClientData } from '../features/clientInfo/clientInfoTypes';
import { IFetchClientsParams } from '../features/clientsList/clientsListTypes';

export const fetchOrganizationClientsList = async (
  params: IFetchClientsParams | void
) => {
  if (params) {
    const { searchValue, skip, take } = params;
    const searchStringQuery =
      searchValue && searchValue.length > 0 ? `search=${searchValue}` : '';
    const skipQuery = `${
      searchValue && searchValue.length > 0 ? '&' : ''
    }skip=${skip}`;
    const takeQuery = `&take=${take}`;
    const queryStr = `?${searchStringQuery}${skipQuery}${takeQuery}`;

    return await apiClient.get(`/clients${queryStr}`);
  } else {
    return await apiClient.get(`/clients`);
  }
};

export const addNewClient = async (data: IAddClientData) => {
  return await apiClient.post(`/clients`, {
    ...data,
  });
};

export const removeClient = async (clientId: number) => {
  return await apiClient.delete(`/clients/${clientId}`);
};

import { apiClient } from './axiosInstance';
import { IHolidayInfoUpdatingData } from 'features/holidayInfo/holidayInfoTypes';

export const fetchHolidayInfo = async (holidayId: number) => {
  return await apiClient.get(`/time-off/holidays/${holidayId}`);
};

export const updateHolidayInfo = async (data: IHolidayInfoUpdatingData) => {
  return await apiClient.patch(`/time-off/holidays/${data.id}`, {
    ...data,
  });
};

import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { FormatOptionLabelMeta, SingleValue } from 'react-select';
import { useAppDispatch } from 'app/store';
import { ISelectOption } from 'components/shared/forms/DropdownSelect/DropdownSelect';
import DropdownSelect from 'components/shared/forms/DropdownSelect';
import {
  memberInfoTimesheetsSelector,
  setSelectedTimeZone,
  selectedTimeZoneTimesheetsSelector,
} from 'features/timesheets/timesheetsSlice';
import { selectInfoUserSelector } from 'features/user/userSlice';
import { organizationInfoSelector } from 'features/organization/organizationSlice';
import styles from './TimesheetsTimeZoneFilter.module.scss';

const TimesheetsTimeZoneFilter: FC = () => {
  const dispatch = useAppDispatch();
  const { timeZone: selectedMemberTimeZone } = useSelector(
    memberInfoTimesheetsSelector
  );
  const selectedTimeZone = useSelector(selectedTimeZoneTimesheetsSelector);
  const userInfo = useSelector(selectInfoUserSelector);
  const { timeZone: organizationTimeZone, name: organizationName } =
    useSelector(organizationInfoSelector);

  const filterListOptions = [
    {
      label: selectedMemberTimeZone.name,
      value: "Member's time zone",
    },
    {
      label: userInfo?.timeZone.name || '',
      value: 'My time zone',
    },
    {
      label: organizationTimeZone.name,
      value: `${organizationName} time zone`,
    },
  ];

  const onFilterValueChange = (option: SingleValue<ISelectOption>) => {
    option && dispatch(setSelectedTimeZone(option));
  };

  const formatOptionLabel = (
    data: ISelectOption,
    formatOptionLabelMeta: FormatOptionLabelMeta<ISelectOption>
  ) => (
    <div className={styles.optionWrapper}>
      {formatOptionLabelMeta.context === 'menu' && (
        <div className={styles.value}>{data.value}</div>
      )}
      <div className={styles.label}>{data.label}</div>
    </div>
  );

  return (
    <div className={styles.timeZoneDropdown}>
      <DropdownSelect
        label="Time zone"
        value={selectedTimeZone}
        options={filterListOptions}
        formatOptionLabel={formatOptionLabel}
        onChange={onFilterValueChange}
        isSearchable={false}
        blurInputOnSelect
        withDescription
      />
    </div>
  );
};

export default TimesheetsTimeZoneFilter;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyKnownError, thunkErrorHandler } from 'features/thunkError';
import { AppDispatch } from 'app/store';
import { IPriceListItem } from './pricesTypes';
import { fetchPrices } from 'api/billing/prices';

export const getPrices = createAsyncThunk<
  IPriceListItem[],
  void,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@prices/getPrices', async (_, thunkApi) => {
  try {
    const res =  await fetchPrices();
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { useAppDispatch } from 'app/store';
import Modal from 'components/shared/Modal';
import {
  changeRateHistoryEntry,
  deleteRateHistoryEntry,
} from 'features/memberInfo/memberInfoActions';
import { RateHistoryEntry } from 'features/memberInfo/memberInfoTypes';
import {
  rateHistoryEntryToEditMemberInfoSelector,
  setRateHistoryEntryToEdit,
} from 'features/memberInfo/memberInfoSlice';
import { dateToTimeZone } from 'helpers/dateToTimeZone';
import EditBillRateHistoryEntryForm from '../EditBillRateHistoryEntryForm';
import EditPayRateHistoryEntryForm from '../EditPayRateHistoryEntryForm';
import styles from './PaymentHistoryTable.module.scss';

interface PaymentHistoryTableProps {
  title: 'Pay rate history' | 'Bill rate history';
  memberId: number;
  data: RateHistoryEntry[];
  memberTimeZone: string;
}

const paymentHistoryTableColumns = [
  { name: 'Status', value: 'status' },
  { name: 'Rate', value: 'rate' },
  { name: 'Type', value: 'type' },
  { name: 'Start date', value: 'startDate' },
] as const;

const PaymentHistoryTable: FC<PaymentHistoryTableProps> = ({
  title,
  memberId,
  data,
  memberTimeZone,
}) => {
  const entryToEdit = useSelector(rateHistoryEntryToEditMemberInfoSelector);
  const dispatch = useAppDispatch();
  const [isEditEntryFormOpen, setIsEditEntryFormOpen] = useState(false);

  const handleRateEntryClick = (entry: RateHistoryEntry) => {
    dispatch(setRateHistoryEntryToEdit(entry));
    setIsEditEntryFormOpen(true);
  };
  const handleRateHistoryEntryChange = (
    data: RateHistoryEntry,
    paymentRateType: 'billRate' | 'payRate'
  ) => {
    dispatch(
      changeRateHistoryEntry({
        memberId,
        payload: { ...data, paymentRateType },
      })
    );
    setIsEditEntryFormOpen(false);
  };
  const handleDeleteRateHistoryEntry = (
    payAmountType: 'pay-rate' | 'bill-rate',
    entryId: number
  ) => {
    dispatch(deleteRateHistoryEntry({ payAmountType, memberId, entryId }));
    setIsEditEntryFormOpen(false);
  };

  return (
    <>
      <div className={styles.table}>
        <h3 className={styles.title}>{title}</h3>
        <div className={styles.row}>
          {paymentHistoryTableColumns.map((col) => (
            <div
              key={col.name}
              className={`${styles.cell} ${styles.headerCell}`}
            >
              {col.name}
            </div>
          ))}
        </div>
        <div className={styles.body}>
          {data.map((entry) => (
            <div
              key={entry.id}
              className={`${styles.row} ${styles.bodyRow}`}
              onClick={() => handleRateEntryClick(entry)}
            >
              {paymentHistoryTableColumns.map((col) => (
                <div
                  key={col.value}
                  className={`${styles.cell} ${styles.bodyCell}`}
                >
                  <span className={`${styles.bodyCellContent}`}>
                    {col.value === 'startDate'
                      ? format(
                          dateToTimeZone(
                            new Date(entry.startDate),
                            "UTC"
                          ),
                          'MMM dd, yyyy'
                        )
                      : entry[col.value]}
                  </span>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      {entryToEdit && (
        <Modal
          title={`${title} entry`}
          open={isEditEntryFormOpen}
          onClose={() => setIsEditEntryFormOpen(false)}
        >
          {title === 'Pay rate history' ? (
            <EditPayRateHistoryEntryForm
              entry={entryToEdit}
              memberTimeZone={memberTimeZone}
              handleSubmit={handleRateHistoryEntryChange}
              handleDelete={handleDeleteRateHistoryEntry}
            />
          ) : (
            <EditBillRateHistoryEntryForm
              entry={entryToEdit}
              memberTimeZone={memberTimeZone}
              handleSubmit={handleRateHistoryEntryChange}
              handleDelete={handleDeleteRateHistoryEntry}
            />
          )}
        </Modal>
      )}
    </>
  );
};

export default PaymentHistoryTable;

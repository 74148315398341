import React, { FC, useState } from 'react';
import { zonedTimeToUtc } from 'date-fns-tz';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { TrashIcon } from 'assets/icons/TrashIcon';
import EditPayRateFormikDatePicker from 'components/shared/forms/datePickers/EditPayRateFormikDatePicker';
import ValidationTextInput from 'components/shared/forms/ValidationTextInput';
import ConfirmDialog from 'components/shared/ConfirmDialog';
import Button from 'components/shared/Button';
import { MaxLength8Symbols } from 'constants/formsConstants';
import { dateToTimeZone } from 'helpers/dateToTimeZone';
import { setTimeInDate } from 'helpers/setTimeInDate';
import { EditRateHistoryEntryFormProps } from '../EditPayRateHistoryEntryForm/EditPayRateHistoryEntryForm';
import styles from 'components/members/editPayments/EditPaymentsForm.module.scss';

const EditBillRateHistoryEntryForm: FC<EditRateHistoryEntryFormProps> = ({
  entry,
  memberTimeZone,
  handleSubmit,
  handleDelete,
}) => {
  const {
    id: entryId,
    type: payType,
    rate: billRate,
    startDate,
    status,
  } = entry;
  const [confirmDeleteEntry, setConfirmDeleteEntry] = useState(false);
  const InitialValues = {
    billRate: billRate,
    startDate: dateToTimeZone(startDate, "UTC"),
  };

  const validationSchema = Yup.object({
    billRate: Yup.number()
      .typeError('You must specify a number')
      .test(
        'len',
        'Enter a smaller number',
        (val = 0) => val.toString().length <= MaxLength8Symbols
      ),
  });

  const handleDeleteHistoryEntry = () => {
    handleDelete('bill-rate', entryId);
    setConfirmDeleteEntry(false);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <Formik
          enableReinitialize
          initialValues={InitialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            const formattedStartDate = zonedTimeToUtc(
              setTimeInDate(values.startDate, 0, 0),
              "UTC"
            ).toISOString();
            handleSubmit(
              {
                id: entryId,
                type: payType,
                rate: values.billRate,
                startDate: formattedStartDate,
                status,
              },
              'billRate'
            );
            setSubmitting(false);
          }}
        >
          {(formik) => (
            <Form>
              <div className={styles.rateFlex}>
                <ValidationTextInput
                  label="Bill rate"
                  name="billRate"
                  type="text"
                  placeholder="Add bill rate USD/hr"
                />

                <EditPayRateFormikDatePicker
                  label="Effective date"
                  name="startDate"
                  maxDate={new Date()}
                />
              </div>

              <div
                className={styles.submitWrapperEditEntryForm}
                style={{ marginBottom: 0 }}
              >
                <div className={styles.deleteBtn}>
                  <Button
                    type="button"
                    variant="delete"
                    onClick={() => setConfirmDeleteEntry(true)}
                  >
                    <TrashIcon />
                  </Button>
                </div>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={formik.isSubmitting}
                  preloader={formik.isSubmitting}
                >
                  Save changes
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <ConfirmDialog
        open={confirmDeleteEntry}
        title="Confirm"
        description="Delete this rate history entry? You will not be able to undo the changes."
        acceptBtnText="Delete"
        declineBtnText="Cancel"
        onClose={() => setConfirmDeleteEntry(false)}
        onAccept={handleDeleteHistoryEntry}
      />
    </>
  );
};

export default EditBillRateHistoryEntryForm;

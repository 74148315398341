import { apiClient } from './axiosInstance';
import { IUpdateOrganizationData } from '../features/organization/organizationTypes';

export const fetchOrganizationInfo = async (id: number) => {
  return await apiClient.get(`/organizations/${id}`);
};

export const changeOrganizationInfo = async (data: IUpdateOrganizationData) => {
  return await apiClient.patch(`/organizations`, { ...data });
};

import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { SubcsriptionSliceInitialState } from './subscriptionTypes';
import { getSubscriptionInfoByOrganozationId, updateSubscriptionInfo } from './subscriptionActions';
import {
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from '../sliceHelpers';

const initialState: SubcsriptionSliceInitialState = {
  subscriptionInfo: {
    id: "",
    name: "",
    status: "",
    quantity: 1,
    currency: "usd",
    interval: "month",
    trialEnd: "",
    noTaxAmount: 0,
    totalAmount: 0,
    taxAmount: 0,
    taxPercentage: 0,
    priceId: "",
    productId: "",
  },
  isLoading: false,
};

export const subscriptionSlice = createSlice({
  name: '@@subscription',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSubscriptionInfoByOrganozationId.fulfilled, (state, action) => {
      state.subscriptionInfo = action.payload;
    });
    builder.addCase(updateSubscriptionInfo.fulfilled, (state, action) => {
      state.subscriptionInfo = action.payload;
    })
    builder.addMatcher(isPendingAction, (state) => {
      state.isLoading = true;
    });
    builder.addMatcher(isFulfilledAction, (state) => {
      state.isLoading = false;
    });
    builder.addMatcher(isRejectedAction, (state) => {
      state.isLoading = false;
    });
  },
});

export const subscriptionInfoSelector = createSelector(
  [(state: RootState) => state.subscription.subscriptionInfo],
  (info) => info
);

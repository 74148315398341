import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'components/shared/Button';
import styles from './OrganizationSuspended.module.scss';
import { useSelector } from 'react-redux';
import { permissionsUserSelector } from 'features/user/userSlice';

const OrganizationSuspended: FC = () => {
  const navigate = useNavigate();

  const permissions = useSelector(permissionsUserSelector);
  const isAccessBillingSessionsAvailable = permissions?.some(
    (el) => el.name === 'AccessBillingSessions'
  );

  return (
    <div className={styles.root}>
      <p>OrganizationSuspended</p>

      {isAccessBillingSessionsAvailable ? 
        <div>
          <p>You cannot access this organization until you pay for the subscription.</p>
          <Button variant="primary" onClick={() => navigate('/billing')}>
            Go to billing page
          </Button>
        </div> : 
        <p>Contact the owner for more details.</p>
      }
    </div>
  );
};

export default OrganizationSuspended;

import React from 'react';
import Select, { GroupBase, Props, StylesConfig } from 'react-select';
import styles from './DropdownSelect.module.scss';

export interface ISelectOption {
  value: string;
  label: string;
}
interface DropdownSelectProps {
  label?: string;
  error?: boolean;
  errorMessage?: string;
  withDescription?: boolean;
}

export default function DropdownSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  label,
  error,
  errorMessage,
  withDescription,
  ...props
}: Props<Option, IsMulti, Group> & DropdownSelectProps) {
  const customStyles: StylesConfig<Option, IsMulti, Group> = {
    control: (base, state) => ({
      ...base,
      width: '100%',
      minHeight: '30px',
      // marginBottom: 20,
      background: '#fff',
      borderWidth: '1px',
      borderColor: error ? '#D80027' : state.isFocused ? '#007AFF' : '#E7E7E7',
      color: '#000000',
      fontSize: 13,
      outline: 'none',
      boxShadow: error
        ? '0 0 7px #D80027'
        : state.isFocused
        ? 'inset 0 2px 2px rgb(0 0 0 / 5%), 0 0 7px rgb(1 104 250 / 50%)'
        : 'none',
      //remove unwanted border effects
      '&:hover': {},
    }),
    menu: (base) => ({
      ...base,
      minWidth: '140px',
      background: '#fff',
      color: '#000000',
      border: 'none',
      borderRadius: 8,
      marginTop: 10,
      paddingTop: 0,
      paddingBottom: 0,
      boxShadow: '0 6px 12px rgb(0 0 0 / 18%)',
      zIndex: 1000,
      fontSize: 13,
    }),
    menuList: (base) => ({
      ...base,
      //remove unwanted border effects
      '&:hover': {},
    }),
    placeholder: (base) => ({
      ...base,
      color: '#64748B',
    }),
    multiValue: (base) => ({
      ...base,
      background: '#fff',
      border: 'solid',
      borderColor: '#E7E7E7',
      borderWidth: 1,
      borderRadius: 8,
      borderLeftWidth: 7,
      borderLeftColor: '#007AFF',
      padding: '4px 0 4px 4px',
      color: '#000000',
      cursor: 'pointer',
    }),
    multiValueLabel: (base) => ({
      ...base,
      fontWeight: 400,
      fontSize: 13,
      padding: '0 3px',
      color: '#000000',
    }),
    multiValueRemove: (base) => ({
      ...base,
      background: '#fff',
      borderRadius: 12,
      color: '#64748B',
      fontWeight: 400,
      '&:hover': {
        background: '#fff',
        color: '#E7E7E7',
      },
    }),
    clearIndicator: (base) => ({
      ...base,
      display: 'none',
    }),
    option: (base, state) => ({
      ...base,
      height: 'auto',
      alignItems: 'center',
      backgroundColor: state.isSelected && !withDescription ? '#007AFF' : 'fff',
      color: state.isSelected && !withDescription ? '#fff' : '#1B1B1B',
      paddingBottom: '5px',
      paddingTop: '5px',
      '&:hover': {
        backgroundColor:
          state.isSelected && !withDescription
            ? '#007AFF'
            : 'rgba(0, 122, 255, 0.1)',
      },
    }),
  };

  return (
    <div className={styles.wrapper}>
      <label className="input-label">{label}</label>
      <Select {...props} styles={customStyles} />
      {error && <div className={styles.errorMessage}>{errorMessage}</div>}
    </div>
  );
}

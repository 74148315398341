import { paymentsClient } from '../axiosInstance';
import { loadStripe } from '@stripe/stripe-js';

interface CreateCheckoutSessionRequest {
    priceId: string;
    successUrl: string;
    failureUrl: string;
    metadata?: { [key: string]: string };
}

interface CreateCheckoutSessionResponse {
    sessionId: string;
    publicKey: string;
}

const paymentSessions = process.env.REACT_APP_PAYMENTS_API + '/sessions'

export const redirectToStripeCheckout = async (requestData: CreateCheckoutSessionRequest) => {
    try {
        const response = await paymentsClient.post<CreateCheckoutSessionResponse>(
            paymentSessions + '/checkout',
            requestData
        );

        const { sessionId, publicKey } = response.data;

        const stripe = await loadStripe(publicKey);

        if (!stripe) {
            throw new Error('Stripe.js not loaded');
        }

        const { error } = await stripe.redirectToCheckout({
            sessionId: sessionId,
        });

        if (error) {
            console.error('Error redirecting to Stripe Checkout:', error.message);
        }
    } catch (error) {
        console.error('Error creating Stripe Checkout session:', error);
    }
};

export const redirectToBillingPortal = async (organizationId: number | undefined) => {
    window.location.href = await getBillingPortalUrl(organizationId);
}

export const redirectToBillingPortalPaymentMethods = async (organizationId: number | undefined) => {
    window.location.href = await getBillingPortalUrl(organizationId) + '/payment-methods';
}

const getBillingPortalUrl = async (organizationId: number | undefined) => {
    try {
        const requestData = {
            returnUrl: window.location.origin,
            organizationId: organizationId
        };

        const response = await paymentsClient.post(paymentSessions + '/billing-portal', requestData);
        const { url } =  response.data;
        
        return url;
    } catch (error) {
        console.error('Error redirecting to billing portal:', error);
    }
}


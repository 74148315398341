export const getCurrencySymbol = (currencyCode: string): string => {
    const currencySymbols: { [key: string]: string } = {
        usd: "$",  // US Dollar
        eur: "€",  // Euro
        gbp: "£",  // British Pound
        krw: "₩",  // South Korean Won
        try: "₺",  // Turkish Lira
        inr: "₹",  // Indian Rupee
        rub: "₽",  // Russian Ruble
        pln: "zł", // Polish Zloty
    };
  
    const formattedCurrencyCode = currencyCode.toLowerCase();
    return currencySymbols[formattedCurrencyCode] || currencyCode;
};
import { apiClient } from './axiosInstance';
import {
  ChangeMemberActivityStatusParams,
  IFetchMembersParams,
} from 'features/members/membersTypes';

export const fetchOrganizationMembersList = async (
  params: IFetchMembersParams | void
) => {
  if (params) {
    const { searchValue, showDeleted, skip, take } = params;
    const showDeletedQuery = `?showDeleted=${showDeleted}`;
    const searchStringQuery =
      searchValue && searchValue.length > 0 ? `&search=${searchValue}` : '';
    const skipQuery = `&skip=${skip}`;
    const takeQuery = `&take=${take}`;
    const queryStr = `${showDeletedQuery}${searchStringQuery}${skipQuery}${takeQuery}`;
    return await apiClient.get(`/members${queryStr}`);
  } else {
    return await apiClient.get(`/members`);
  }
};

export const changeMemberActivityStatus = async (
  params: ChangeMemberActivityStatusParams
) => {
  const { currentStatus, memberId } = params;
  if (currentStatus) {
    return await apiClient.patch(`/members/${memberId}/disable`, {});
  } else {
    return await apiClient.patch(`/members/${memberId}/enable`, {});
  }
};

export const removeMemberById = async (memberId: number) => {
  return await apiClient.delete(`/members/${memberId}`);
};

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { userManager } from '../features/user/userSlice';

const createClient = (baseURL: string) => {
  const client = axios.create({
    headers: { 'Content-Type': 'application/json' },
    baseURL: baseURL,
  });

  client.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
      const user = await userManager.getUser();
      const token = user?.access_token;
      if (token && config.headers) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  client.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    async (error) => {
      if (error.response?.status === 401) {
        await userManager.signinRedirect();
      }
      if (error.response?.status === 402) {
        window.location.href = '/organizationSuspended';
      }
      if (error.response?.status === 403) {
        window.location.href = '/403';
      }
      return Promise.reject(error);
    }
  );

  return client;
};

const apiClient = createClient(process.env.REACT_APP_BACKEND_API as string);
const paymentsClient = createClient(process.env.REACT_APP_PAYMENTS_API as string);
const jiraClient = createClient(process.env.REACT_APP_JIRA_API as string);

export { apiClient, paymentsClient, jiraClient };

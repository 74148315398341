import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import {
  setSelectedMemberId,
  membersFilterListTimesheetsSelector,
  selectedMemberIdTimesheetsSelector,
} from 'features/timesheets/timesheetsSlice';
import { SingleValue } from 'react-select';
import { useAppDispatch } from 'app/store';
import { ISelectOption } from 'components/shared/forms/DropdownSelect/DropdownSelect';
import DropdownSelect from 'components/shared/forms/DropdownSelect';
import styles from './TimesheetsMemberFilter.module.scss';

const TimesheetsMemberFilter: FC = () => {
  const dispatch = useAppDispatch();
  const filterList = useSelector(membersFilterListTimesheetsSelector);
  const selectedMemberId = useSelector(selectedMemberIdTimesheetsSelector);
  const filterListOptions = filterList.map((m) => ({
    value: String(m.id),
    label: m.name,
  }));
  const selectedOption = filterListOptions.find(
    (el) => Number(el.value) === selectedMemberId
  );
  const onFilterValueChange = (option: SingleValue<ISelectOption>) => {
    option && dispatch(setSelectedMemberId(Number(option.value)));
  };

  return (
    <div className={styles.membersDropdown}>
      <DropdownSelect
        label="Member"
        value={selectedOption}
        options={filterListOptions}
        onChange={onFilterValueChange}
        isSearchable={false}
        blurInputOnSelect
      />
    </div>
  );
};;

export default TimesheetsMemberFilter;

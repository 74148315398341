import React, { useState } from 'react';
import styles from './SubscriptionDetails.module.scss';
import Button from 'components/shared/Button';
import { useAppDispatch } from 'app/store';
import { useSelector } from 'react-redux';
import { subscriptionInfoSelector } from 'features/subscription/subscriptionSlice';
import { IUpdateSubscriptionData } from 'features/subscription/subscriptionTypes';
import { updateSubscriptionInfo } from 'features/subscription/subscriptionActions';
import { pricesSelector } from 'features/prices/pricesSlice';
import { itemsTotalCountMembersSelector } from 'features/members/membersSlice';

interface SubscriptionDetailsProps {
  onClose: () => void;
  showSuccessNotification: (message: string) => void;
  showErrorNotification: (message: string) => void;
}

const EditSubscriptionDetailsForm: React.FC<SubscriptionDetailsProps> = ({ onClose, showSuccessNotification, showErrorNotification }) => {
  const dispatch = useAppDispatch();
  const subscriptionInfo = useSelector(subscriptionInfoSelector);
  const prices = useSelector(pricesSelector);

  const [teamMembers, setTeamMembers] = useState<number>(subscriptionInfo.quantity);
  const [billingPeriod, setBillingPeriod] = useState<'month' | 'year'>(subscriptionInfo.interval);
  
  const handleIncrement = () => setTeamMembers(prev => prev + 1);
  const handleDecrement = () => setTeamMembers(prev => (prev > 1 ? prev - 1 : 1));
  const handlePeriodChange = (period: 'month' | 'year') => setBillingPeriod(period);

  const handleSubmit = () => {
    const currentPrice = prices.find(x => x.id === subscriptionInfo.priceId);
    const newPrice = prices.find(x => 
      x.id !== currentPrice?.id &&
      x.productId === currentPrice?.productId &&
      x.currency === subscriptionInfo.currency
    )

    const updateSubscriptionData: IUpdateSubscriptionData = {
      subscriptionId: subscriptionInfo.id,
      priceId: subscriptionInfo.priceId, 
      quantity: teamMembers, 
    }

    // if(teamMembers < membersCount){
    //   showErrorNotification('The number of subscription seats cannot be less than the number of active members.')
    // }

    if(newPrice && subscriptionInfo.interval !== billingPeriod) 
    {
      updateSubscriptionData.priceId = newPrice.id;
    }

    dispatch(updateSubscriptionInfo(updateSubscriptionData));
    onClose();
  }

  return (
    <div className={styles.subscriptionDetails}>      
      <div className={styles.billingToggle}>
        <button
          className={billingPeriod === 'month' ? styles.active : ''}
          onClick={() => handlePeriodChange('month')}
        >
          Monthly
        </button>
        <button
          className={billingPeriod === 'year' ? styles.active : ''}
          onClick={() => handlePeriodChange('year')}
        >
          Annual
        </button>
      </div>
      
      <p className={styles.freeText}>1 Month free with annual plan</p>
      
      <div className={styles.teamMembersControl}>
        <label className={styles.memberName}>Team members:</label>
        <button onClick={handleDecrement}>-</button>
        <input type="text" value={teamMembers} readOnly />
        <button onClick={handleIncrement}>+</button>
      </div>
      
      <div className={styles.planSummary}>
        <p>Current plan summary:</p>
        <ul>
          <li>✔ Premium pricing plan</li>
          <li>✔ {billingPeriod === 'month' ? 'Monthly' : 'Annual'} billing period</li>
          <li>✔ Up to {teamMembers} users</li>
        </ul>
      </div>
      
      <div className={styles.formFooter}>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default EditSubscriptionDetailsForm;

import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { PricesSliceInitialState } from './pricesTypes';
import { getPrices } from './pricesActions';
import {
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from '../sliceHelpers';

const initialState: PricesSliceInitialState = {
  prices: [],
  isLoading: false,
};

export const pricesSlice = createSlice({
  name: '@@prices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPrices.fulfilled, (state, action) => {
      state.prices = action.payload;
    });
    builder.addMatcher(isPendingAction, (state) => {
      state.isLoading = true;
    });
    builder.addMatcher(isFulfilledAction, (state) => {
      state.isLoading = false;
    });
    builder.addMatcher(isRejectedAction, (state) => {
      state.isLoading = false;
    });
  },
});

export const pricesSelector = createSelector(
  [(state: RootState) => state.prices.prices],
  (info) => info
);

import React from 'react';
import styles from './Notification.module.scss';

interface NotificationProps {
  message: string;
  isOpen: boolean;
  isSuccessful: boolean; 
  onClose: () => void;
}

const Notification: React.FC<NotificationProps> = ({ message, isOpen, isSuccessful, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className={`${styles.notification} ${isSuccessful ? styles.success : styles.error}`}>
      <span className={styles.message}>{message}</span>
      <button className={styles.closeButton} onClick={onClose}>✖</button>
    </div>
  );
};

export default Notification;

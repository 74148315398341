import React, { FC, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/store';
import TimesheetsTimeZoneFilter from 'components/timesheets/TimesheetsTimeZoneFilter';
import TimesheetsMemberFilter from 'components/timesheets/TimesheetsMemberFilter';
import AddTimeFloatBtn from 'components/timesheets/AddTimeFloatBtn';
import AddTimeForm from 'components/timesheets/AddTimeForm';
import Preloader from 'components/shared/Preloader';
import Calendar from 'components/timesheets/Calendar';
import Button from 'components/shared/Button';
import Modal from 'components/shared/Modal';
import {
  isLoadingTimeEntriesSelector
} from 'features/timeEntries/timeEntriesSlice';
import {
  setInitialCalendarView,
  totalTimeLoggedCalendarInfoSelector
} from 'features/calendarInfo/calendarInfoSlice'
import {
  permissionsUserSelector,
  selectInfoUserSelector,
} from 'features/user/userSlice';
import {
  memberInfoTimesheetsSelector,
  setSelectedMemberId,
  setSelectedTimeZone,
  selectedMemberIdTimesheetsSelector,
  selectedTimeZoneTimesheetsSelector,
  addTimeFormStateTimesheetsSelector,
} from 'features/timesheets/timesheetsSlice';
import {
  getMemberTimesheetsInfo,
  getTimesheetsFilterlist,
} from 'features/timesheets/timesheetsActions';
import styles from './Timesheets.module.scss';

const Timesheets: FC = () => {
  const screenWidth = window.innerWidth;
  const mobileDevices = screenWidth < 1024;
  const { ref: inViewRef, inView } = useInView({
    threshold: 0.3,
  });
  const addTimeFormState = useSelector(addTimeFormStateTimesheetsSelector);
  const selectedMemberId = useSelector(selectedMemberIdTimesheetsSelector);
  const selectedTimeZone = useSelector(selectedTimeZoneTimesheetsSelector);
  const totalTimeLogged = useSelector(totalTimeLoggedCalendarInfoSelector);
  const timeEntriesLoading = useSelector(isLoadingTimeEntriesSelector);
  const memberInfo = useSelector(memberInfoTimesheetsSelector);
  const permissions = useSelector(permissionsUserSelector);
  const userInfo = useSelector(selectInfoUserSelector);
  const dispatch = useAppDispatch();
  const [addTimeFormOpen, setAddTimeFormOpen] = useState(false);
  const handleOpenAddTimeForm = () => setAddTimeFormOpen(true);
  const handleCloseAddTimeForm = () => {
    setAddTimeFormOpen(false);
  };

  const isTimeAddingAvailable = permissions?.some(
    (el) => el.name === 'Add TimeEntry'
  );

  useEffect(() => {
    userInfo &&
      dispatch(
        setSelectedTimeZone({
          label: userInfo.timeZone.name || '',
          value: 'My time zone',
        })
      );
  }, [userInfo?.timeZone, memberInfo]);

  useEffect(() => {
    userInfo?.id &&
      isTimeAddingAvailable &&
      dispatch(setSelectedMemberId(userInfo?.id));
  }, [userInfo?.id]);

  useEffect(() => {
    dispatch(getTimesheetsFilterlist());

    dispatch(
      setInitialCalendarView(
        screenWidth >= 1024 ? 'dayGridMonth' : 'timeGridDay'
      )
    );
  }, []);

  useEffect(() => {
    if (selectedMemberId) {
      dispatch(getMemberTimesheetsInfo(selectedMemberId));
    }
  }, [selectedMemberId]);

  return (
    <>
      <div className={styles.controls}>
        <div className={styles.dropdowns}>
          <div className={styles.tzFilterWrapper}>
            <TimesheetsTimeZoneFilter />
          </div>
          <div className={styles.memberFilterWrapper}>
            <TimesheetsMemberFilter />
          </div>
        </div>
        {isTimeAddingAvailable && (
          <div className={styles.addButton}>
            <Button
              ref={inViewRef}
              onClick={handleOpenAddTimeForm}
              variant="primary"
            >
              Add time
            </Button>
          </div>
        )}
      </div>

      <div className={styles.totalTimeLogged}>
        Total time:{' '}
        {timeEntriesLoading ? (
          '...'
        ) : (
          <span className={styles.time}>{totalTimeLogged}</span>
        )}
      </div>

      <div className={styles.calendarWrapper}>
        {selectedTimeZone ? (
          <Calendar
            addTimeFormOpen={addTimeFormOpen}
            openAddTimeForm={handleOpenAddTimeForm}
            selectedMemberId={selectedMemberId || null}
            selectedTimeZoneName={selectedTimeZone.label}
          />
        ) : (
          <Preloader />
        )}
      </div>

      {addTimeFormState && isTimeAddingAvailable && (
        <Modal
          title="Add time"
          open={addTimeFormOpen}
          onClose={handleCloseAddTimeForm}
        >
          <AddTimeForm
            data={addTimeFormState}
            handleClose={handleCloseAddTimeForm}
          />
        </Modal>
      )}
      {mobileDevices && !inView && isTimeAddingAvailable && (
        <AddTimeFloatBtn onClick={handleOpenAddTimeForm} />
      )}
    </>
  );
};

export default Timesheets;

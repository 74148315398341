import React, { useEffect, useState } from 'react';
import styles from './Billing.module.scss';
import Modal from 'components/shared/Modal';
import SubscriptionDetails from 'components/billing/SubscriptionDetails';
import { redirectToBillingPortalPaymentMethods } from 'api/billing/sessions';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/store';
import { getSubscriptionInfoByOrganozationId } from 'features/subscription/subscriptionActions';
import { subscriptionInfoSelector } from 'features/subscription/subscriptionSlice';
import { getMembers } from 'features/members/membersActions';
import { itemsTotalCountMembersSelector } from 'features/members/membersSlice';
import { selectInfoUserSelector } from 'features/user/userSlice';
import { getPrices } from 'features/prices/pricesActions';
import SubscriptionPlans from 'components/billing/SubscriptionPlans';
import Notification from 'components/shared/Notification';
import { getCurrencySymbol } from 'helpers/getCurrencySymbol';

const Billing: React.FC = () => {
  const userInfo = useSelector(selectInfoUserSelector);
  const subscriptionInfo = useSelector(subscriptionInfoSelector);
  const membersCount = useSelector(itemsTotalCountMembersSelector);

  const dispatch = useAppDispatch();

  const [isSubscriptionDetailsModalOpen, setSubscriptionDetailsModalOpen] = useState(false);
  const [isPricingPlanModalOpen, setPricingPlanModalOpen] = useState(false);
  const [notificationInfo, setNotificationInfo] = useState({
    isOpen: false,
    message: '',
    isSuccessful: false,
  });

  useEffect(() => {
    if (userInfo?.organizationId) {
      dispatch(getSubscriptionInfoByOrganozationId(userInfo.organizationId));
      dispatch(getMembers());
      dispatch(getPrices())
    }
  }, [userInfo]);

  const showSuccessNotification = (message: string) => {
    setNotificationInfo({isOpen: true, message: message, isSuccessful: true});
  };
  const showErrorNotification = (message: string) => {
    setNotificationInfo({isOpen: true, message: message, isSuccessful: false});
  };
  
  return (
    <div className={styles.billing}>
      <h1>Billing</h1>

      <Notification
        onClose={() => { setNotificationInfo({...notificationInfo, isOpen: false}) }}
        isOpen={notificationInfo.isOpen}
        isSuccessful={notificationInfo.isSuccessful}
        message={notificationInfo.message}
      />

      <div className={styles.paymentMethods}>
        <h2>Payment methods</h2>
        <p>You will be automatically charged for the subscription at the end of your free trial.</p>
        <button 
          className={styles.btnPrimary} 
          onClick={() => redirectToBillingPortalPaymentMethods(userInfo?.organizationId)}
          >
            Add payment method
        </button>
      </div>

      <div className={styles.billingDetails}>
        <div className={`${styles.section} ${styles.teamMembers}`}>
          <h3>Team members count</h3>
          <p>Up to {subscriptionInfo.quantity} member</p>
          <p>{membersCount} current member</p> 
          <button className={styles.btnSecondary} onClick={() => setSubscriptionDetailsModalOpen(true)}>Add more</button>
        </div>

        <div className={`${styles.section} ${styles.billingPeriod}`}>
          <h3>Billing period</h3>
          <p>{subscriptionInfo.interval} billing</p>
          <p>Get 1 month free with annual billing.</p>
          <button className={styles.btnSecondary} onClick={() => setSubscriptionDetailsModalOpen(true)}>Change billing period</button>
        </div>

        <div className={`${styles.section} ${styles.pricingPlan}`}>
          <h3>Pricing plan</h3>
          <p>{subscriptionInfo.name}</p>
          <p>
            {getCurrencySymbol(subscriptionInfo.currency)}{subscriptionInfo.noTaxAmount ?? 0} + {getCurrencySymbol(subscriptionInfo.currency)}{subscriptionInfo.taxAmount ?? 0} VAT
          </p>
          <p>Total: {getCurrencySymbol(subscriptionInfo.currency)}{(subscriptionInfo.totalAmount ?? 0)}</p>
          <button className={styles.btnSecondary} onClick={() => setPricingPlanModalOpen(true)}>Change plan</button>
        </div>
      </div>

      <Modal
        title="Subscription details"
        open={isSubscriptionDetailsModalOpen}
        onClose={() => setSubscriptionDetailsModalOpen(false)}
      >
        <SubscriptionDetails
          onClose={() => setSubscriptionDetailsModalOpen(false)}
          showSuccessNotification = {showSuccessNotification}
          showErrorNotification = {showErrorNotification}
        />
      </Modal>

      <Modal
        title="Change Pricing Plan"
        open={isPricingPlanModalOpen}
        onClose={() => setPricingPlanModalOpen(false)}
      >
        <SubscriptionPlans
          onClose={() => setPricingPlanModalOpen(false)}
          showSuccessNotification = {showSuccessNotification}
          showErrorNotification = {showErrorNotification}
        />
      </Modal>
    </div>
  );
};

export default Billing;
